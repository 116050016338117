import React from 'react';

import StructureDiv from '../components/__structures/StructureDiv';
import HorizontalNavsCustom1 from '../components-custom/HorizontalNavsCustom1';
import SignInCustom2 from '../components-custom/SignUpCustom';

const meta = {};

export default function Signup() {
  return (
    <React.Fragment>
      <StructureDiv bucket1={[<HorizontalNavsCustom1 />]} />

      <SignInCustom2 />
    </React.Fragment>
  );
}

