import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import {logout} from '../firebaseConfig.ts'


const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  linkBrand: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  },
  linkBrandSmall: {
    display: 'none',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'inline-block',
    }
  },
  secondaryButton: {
    marginRight: theme.spacing(2),
  },
  drawerContainer: {
    width: 256,
  }
}
));

export default function Component(props) {
  const classes = useStyles();

  const [state, setState] = React.useState({ open: false });

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, open });
  };

 // Modified logout function
 const handleLogout = async () => {
    try {
      await logout();
      // Optionally redirect user after logging out
      // history.push('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };


  return (
<AppBar position="static">
  <Toolbar>
   
    <Link href="#" variant="h5" color="inherit" underline="none" className={classes.linkBrand}>
      <img src="https://static.shuffle.dev/uploads/files/5a/5a14da5767fef7d1842df02336b8c145a44c6506/white-logo4.png" alt="" width="120" />
    </Link>
    

        <Button color="inherit" className={classes.secondaryButton} onClick={handleLogout}>Logout</Button>
   
  </Toolbar>
  <Drawer anchor="left" open={state.open} onClose={toggleDrawer(false)}>
    <div className={classes.drawerContainer}>
      
    </div>
  </Drawer>
</AppBar>
  );
}