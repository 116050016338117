import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { emphasize, fade, darken, lighten } from '@material-ui/core/styles/colorManipulator';

import clsx from 'clsx';



const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: '100%',
    alignment: 'centre',
  },
}
));

export default function Component(props) {
  const classes = useStyles();

  return (
<img src="https://static.shuffle.dev/uploads/files/5a/5a14da5767fef7d1842df02336b8c145a44c6506/jobs-in-construction-building-white.svg" alt="" className={classes.image} />
  );
}