import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    backgroundColor: theme.palette.action.selected,
  },
  premiumCardHeader: {
    backgroundColor: '#007BFF', // Assuming blue is the color you want
    color: '#ffffff',  // White text
  },
}));

export default function Component(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleSelect = (selection) => {
    navigate(`/postjob/${selection}`);
  };

  return (
    <section>
      <Container maxWidth="md">
        <Box pt={8} pb={10} textAlign="center">
          <Box mb={6}>
            <Typography variant="h4" component="h2" gutterBottom={true}>Our Listings</Typography>
            <Typography variant="subtitle1" color="textSecondary">Choose between two great prices</Typography>
          </Box>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader title="Standard" className={classes.cardHeader}></CardHeader>
                <CardContent>
                  <Box pt={2} pb={1} px={1}>
                    <Typography variant="h3" component="h2" gutterBottom={true}>
                      £0.99
                    </Typography>
                    <Typography variant="body1" component="p">
                      Off Peak Listing.
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions>
              <Button 
                variant="outlined" 
                fullWidth 
                color="primary" 
                onClick={() => handleSelect('standard')}
              >
                Select
              </Button>
            </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader title="Premium" className={classes.premiumCardHeader}></CardHeader>
                <CardContent>
                  <Box pt={2} pb={1} px={1}>
                    <Typography variant="h3" component="h2" gutterBottom={true}>
                      £4.99
                    </Typography>
                    <Typography variant="body1" component="p">
                      Advertise during peak times for maximum reach.
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions>
              <Button 
                variant="outlined" 
                fullWidth 
                color="primary" 
                onClick={() => handleSelect('premium')}
              >
                Select
              </Button>
            </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}
