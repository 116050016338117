import React, { FC, useContext, useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Paper,
  CircularProgress,
  Snackbar,
} from '@material-ui/core';
import { uploadImageToStorage } from '../firebaseConfig';
import ApiService from '../services/apiService';
import { useNavigate } from 'react-router-dom';
import { JobContext } from '../contexts/JobContext';
import { Job }  from '../pages/types';
import imageCompression from 'browser-image-compression';


// Define the props interface
interface PaymentSuccessProps {
  file: File | null; // Assuming the file is of type File or null
}

const PaymentSuccess: FC<PaymentSuccessProps> = ({ file }) => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const context = useContext(JobContext);

  const compressImage = async (imageFile: File): Promise<File> => {
    const options = {
      maxSizeMB: 1,  // (default: Number.POSITIVE_INFINITY)
      maxWidthOrHeight: 1920,  // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
      useWebWorker: true,  // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
    };
  
    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;  // This is a Blob type
    } catch (error) {
      console.error('Error occurred while compressing image:', error);
      throw error;  // Re-throw the error so it can be caught and handled in your component
    }
  };
  
  if (!context) {
    throw new Error('Component must be used within a JobProvider');
  }

  const { jobs } = context;

  useEffect(() => {
    const saveJobImage = async () => {
      setLoading(true);
      const item = localStorage.getItem('augmentedJobs');
      console.log('Raw item from localStorage:', item);
      const savedJobs: Job[] = item ? JSON.parse(item) : [];
      console.log('local.storage.savedJobs:', savedJobs);
    

      try {
        for (let job of savedJobs) {
            console.log('Job image:', job.image); 
          
            if (job.image) {
            console.log('Uploading image for job:', job);
           
            const compressedImage = await compressImage(job.image);
            const downloadURL: string = await uploadImageToStorage('jobs', job.image) as string;
            console.log('Image uploaded, downloadURL:', downloadURL);
            job.imageUrl = downloadURL; // Use downloadURL
          }
        }
        console.log(
          '29.Job data received by PaymentSuccess component:',
          savedJobs
        );
        console.log('Posting jobs to API:', savedJobs); 
        const response = await ApiService.postJobs(savedJobs); // Use the ApiService correctly
        console.log('Job saved:', response);

        if (response) {
          setSuccessMessage('Successfully posted your job!');
          localStorage.removeItem('augmentedJobs');
        }


      } catch (err) {
        console.error('Error object:', err);
        if (err instanceof Error) {
          console.error('Error stack:', err.stack);
          setErrorMessage(err.message);
        } else {
          setErrorMessage('An unknown error occurred');
        }
        setErrorMessage(
          'There was an error posting your job. Please try again later.'
        );
      } finally {
        setLoading(false);
      }
    };

    saveJobImage(); // Corrected the function name
  }, [jobs, file]);

  return (
    <Paper
      style={{
        padding: '20px',
        width: '600px',
        margin: '0 auto',
        marginTop: '50px',
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : successMessage ? (
        <>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: '20px' }}
          >
            {successMessage}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/dashboard')}
          >
            Go to Dashboard
          </Button>
        </>
      ) : errorMessage ? (
        <>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: '20px', color: 'red' }}
          >
            {errorMessage}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/dashboard')}
          >
            Go to Dashboard
          </Button>
        </>
      ) : null}
    </Paper>
  );
};
export default PaymentSuccess;
