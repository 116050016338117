import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Firebase imports
import { createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth, sendPasswordReset } from '../firebaseConfig.ts';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ApiService from '../services/apiService';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
}));

export default function Component(props) {
  const classes = useStyles();

  // States for form fields and errors
  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    password: '',
    confirmPassword: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields({
      ...fields,
      [name]: value,
    });
  };
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();


  const handleSignUp = async (e) => {
    e.preventDefault();

    try {
      setLoading(true); // Start the loading state

      // Register user in Firebase
      await createUserWithEmailAndPassword(auth, fields.email, fields.password);

      // Register user in your API
      const userData = {
        name: `${fields.firstName} ${fields.lastName}`, // Concatenate first and last name with space
        email: fields.email,
        company: fields.company,
      };
      await registerUserInApi(userData);

      // Navigate to dashboard after successful registration in both systems
      navigate('/dashboard');
    } catch (error) {
      handleSignUpError(error);
    } finally {
      setLoading(false); // End the loading state regardless of success or failure
    }
  };

  const registerUserInApi = async (userData) => {
    try {
      await ApiService.createUser(userData);
    } catch (apiError) {
      console.error('API registration error:', apiError);
      throw new Error(
        'Error registering user in our system. Please try again later.'
      );
    }
  };

  const handleSignUpError = (error) => {
    console.error('Error signing up:', error);
    if (error.message.includes('Error registering user')) {
      setErrors({ api: error.message });
    } else {
      setErrors({ firebase: error.message });
    }
  };

  return (
    <section position="relative">
      <Container maxWidth="xs">
        <Box pt={8} pb={10}>
          <Box mb={3} textAlign="center">
            <Typography variant="h5" component="h2">
              Create a new account
            </Typography>
          </Box>
          <Box>
            <form noValidate onSubmit={handleSignUp}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    autoComplete="fname"
                    name="firstName"
                    id="firstName"
                    label="First name"
                    value={fields.firstName}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="lastName"
                    id="lastName"
                    label="Last name"
                    value={fields.lastName}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="email"
                    id="email"
                    label="Email address"
                    autoComplete="email"
                    value={fields.email}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="company"
                    id="company"
                    label="Company"
                    value={fields.company}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    value={fields.password}
                    onChange={handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    id="confirmPassword"
                    value={fields.confirmPassword}
                    onChange={handleInputChange}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox name="terms" value="1" color="primary" />
                    }
                    label="I agree to the terms of use and privacy policy."
                  />
                </Grid>
              </Grid>
              <Box my={2}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                >
                  Sign up
                </Button>
              </Box>
              <Box textAlign="right">
                <RouterLink to="/login" style={{ textDecoration: 'none' }}>
                  <Link variant="body2">Already have an account? Sign in</Link>
                </RouterLink>
              </Box>
            </form>
          </Box>
        </Box>
        {loading && (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        )}

        {errors.firebase && <Alert severity="error">{errors.firebase}</Alert>}
        {errors.api && <Alert severity="error">{errors.api}</Alert>}
      </Container>
    </section>
  );
}
