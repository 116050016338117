import React, { createContext, useState, FC } from 'react';
import {Job} from '../pages/types'


export const JobContext = createContext<JobContextProps | undefined>(undefined);

interface JobContextProps {
    jobs: Job[];
    addJob: (job: Job) => void;
    removeJob: (jobId: number) => void;
    editJob: (jobId: number, updatedJob: Job) => void;
    updateAllJobs: (updatedJobs: Job[]) => void;
  }


export const JobProvider: FC = ({ children }) => {
    const [jobs, setJobs] = useState<Job[]>([]);
    const [nextId, setNextId] = useState<number>(1);


    const addJob = (job: Job) => {
        setJobs(prevJobs => [...prevJobs, { ...job, id: nextId }]);
        setNextId(prevId => prevId + 1);
        console.log('12.jobs.context=',jobs)
    };

    const removeJob = (jobId: number) => {
        setJobs(prevJobs => prevJobs.filter(job => job.id !== jobId));
    };

    const updateAllJobs = (updatedJobs: Job[]) => {
        setJobs(updatedJobs);
    };

    const editJob = (jobId: number, updatedJob: Job) => {
        setJobs(prevJobs => prevJobs.map(job => (job.id === jobId ? { ...updatedJob, id: jobId } : job)));
    };
    console.log('jobs.saved.21',jobs)
    return (
        <JobContext.Provider value={{ jobs, addJob, removeJob, editJob, updateAllJobs }}>
            {children}
        </JobContext.Provider>
    );
};

