import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { JobProvider } from './contexts/JobContext';
import { AuthProvider } from './contexts/AuthContext';
import AddJob from './components-custom/AddJob';
import JobList from './components-custom/JobList';
import ProtectedRoute from './components-custom/PrivateRoute';

import IndexPage from './pages/Index.js';
import SignupPage from './pages/Signup.js';
import DashboardPage from './pages/Dashboard.js';
import NewjobPage from './pages/Newjob.js';
import Login from './pages/Login.js';
import ProfilePage from './pages/Profile.js';
import PostJob from './pages/PostJob.tsx';
import CheckoutParent from './pages/CheckoutParent.js';
import PaymentSuccess from './pages/PaymentSuccess.tsx';
import PaymentFail from './pages/PaymentFail';
import PrivacyPolicy from './pages/Privacy';
import ContactPage from './pages/Contact';


export default function App() {
  return (
    <AuthProvider>
      <JobProvider>
        <Router>
          <Routes>
            <Route path="/" element={<IndexPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/login" element={<Login />} />
            <Route path= "/privacy" element= {<PrivacyPolicy/>}/>
            <Route path= "/contact" element= {<ContactPage/>}/>
            {/* Protected Routes */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <DashboardPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/checkoutparent"
              element={
                <ProtectedRoute>
                  <CheckoutParent />
                </ProtectedRoute>
              }
            />

            <Route
              path="/payment-success"
              element={
                <ProtectedRoute>
                  <PaymentSuccess />
                </ProtectedRoute>
              }
            />

            <Route
              path="/payment-cancelled"
              element={
                <ProtectedRoute>
                  <PaymentFail />
                </ProtectedRoute>
              }
            />

            <Route
              path="/newjob"
              element={
                <ProtectedRoute>
                  <NewjobPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/postjob/:selection"
              element={
                <ProtectedRoute>
                  <PostJob />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </JobProvider>
    </AuthProvider>
  );
}
