import React from 'react';

import StructureDiv from '../components/__structures/StructureDiv';
import HorizontalNav1 from '../components/horizontal-navs/HorizontalNav1';

const meta = {};

export default function Newjob() {
  return (
    <React.Fragment>
      <StructureDiv bucket1={[<HorizontalNav1 content={null} />]} />
    </React.Fragment>
  );
}

