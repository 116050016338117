import React from 'react';

import StructureContainer from '../components/__structures/StructureContainer';
import HorizontalNavsCustom1 from '../components-custom/HorizontalNavsCustom1';
import CallToActionCustom2 from '../components-custom/CallToActionCustom2';
import StructureDiv from '../components/__structures/StructureDiv';
import ElementsCustom3 from '../components-custom/ElementsCustom3';
import FootersCustom4 from '../components-custom/FootersCustom4';
//
const meta = {};

export default function Index() {
  return (
    <React.Fragment>
      <StructureContainer
        bucket1={[
          <HorizontalNavsCustom1 />,

          <StructureContainer
            bucket1={[
              <CallToActionCustom2 />,

              <StructureDiv
                bucket1={[<ElementsCustom3 />, <FootersCustom4 />]}
              />,
            ]}
          />,
        ]}
      />
    </React.Fragment>
  );
}

