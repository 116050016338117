import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function Element(props) {
  const content = {
    'h5_copy': 'Active Listings',
    ...props.content
  };
 
  return (
    <Typography variant="h5" gutterBottom={true} style={{ paddingLeft: '16px' , paddingTop: '16px'}}>
    {content['h5_copy']}
  </Typography>
  );
}