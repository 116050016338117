import { auth } from '../firebaseConfig';
import { Job } from '../pages/types'



interface UserData {
  name: string;
  email: string;
  company: string;
}

class ApiService {
  baseUrl =
    'https://us-central1-jobs-in-construction-806cc.cloudfunctions.net/api'; // replace with your Firebase Cloud Functions endpoint URL

  // Fetch user token
  async _fetchToken(): Promise<string> {
    if (!auth.currentUser) {
      throw new Error('No user authenticated');
    }
    return auth.currentUser.getIdToken(true);
  }

  // Generic fetch wrapper
  async _fetch(url: string, method: string = 'GET', data: any = null): Promise<any> {
    let token: string;

    try {
      token = await this._fetchToken();
      console.log('token: ', token);
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error fetching the token:', error.message);
      } else {
        console.error('An unknown error occurred:', error);
      }
      console.log('throw.error', error)
      throw error; // propagate the error so that the caller can handle it
    }

    const options: RequestInit = {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-App-Type': 'employer-portal', // Adding the app type header
      },
    };

    if (data) {
      options.body = JSON.stringify(data);
    }

    return fetch(`${this.baseUrl}${url}`, options).then((res) => {
      if (!res.ok) {
        return res.json().then(err => Promise.reject(err));
      }
      return res.json();
    });
  }

  // Define methods to interact with your API endpoints
  postJobs(jobs: Job[]): Promise<any> {
    return this._fetch('/postJobs', 'POST', jobs);
  }

  createCheckoutSession(jobListings: Job[]): Promise<any> {
    return this._fetch('/createCheckoutSession', 'POST', { jobListings });
  }

  createUser(userData: UserData): Promise<any> {
    return this._fetch('/createUser', 'POST', userData);
  }

  getUser(email: string): Promise<any> {
    return this._fetch(`/user?email=${email}`);
  }

  updateUser(userData: UserData): Promise<any> {
    // <-- New method to update user details
    return this._fetch('/user', 'PUT', userData);
  }

  getJobs(email: string): Promise<any> {
    return this._fetch(`/getJobs?email=${email}`);
  }
}

export default new ApiService();

