import React from 'react';

import StructureDiv from '../components/__structures/StructureDiv';
import HorizontalNavsCustom1 from '../components-custom/HorizontalNavsCustom1';
import FootersCustom3 from '../components-custom/FootersCustom3';

// Importing Material-UI components for formatting
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

const meta = {};

const ContactPage = () => {
    return (
        <React.Fragment>
            <StructureDiv bucket1={[<HorizontalNavsCustom1 />]} />

            <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Typography variant="h4" gutterBottom>Contact Us</Typography>
                    <Typography variant="h6" gutterBottom>Jobs in Construction</Typography>
                    <Typography variant="body1">
                        If you have any questions, suggestions, or feedback, we'd love to hear from you. Here's how you can get in touch:
                    </Typography>
                    <Typography variant="subtitle1" style={{ marginTop: '20px' }}>
                        <strong>Email:</strong> [Your Email]
                    </Typography>
                    <Typography variant="subtitle1">
                        <strong>Phone:</strong> [Your Phone Number]
                    </Typography>
                    <Typography variant="subtitle1">
                        <strong>Address:</strong> [Your Address]
                    </Typography>
                    <Typography variant="body1" style={{ marginTop: '20px' }}>
                        Our dedicated team is available Monday to Friday from 9am to 5pm. We'll do our best to get back to you as soon as possible.
                    </Typography>
                </Paper>
            </Container>

            <StructureDiv bucket1={[<FootersCustom3 />]} />
        </React.Fragment>
    );
}

export default ContactPage;
