import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { auth } from '../firebaseConfig';
import { onAuthStateChanged, User } from 'firebase/auth';

interface AuthContextProps {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  isAuthenticated: boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true); // new state to track loading state

  
  const isAuthenticated = Boolean(user);

  useEffect(() => {
    // This listener is triggered when the user signs in, signs out, on initial app load, etc.
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        // User is signed in. Set the user object.
        setUser(firebaseUser);
      } else {
        // User is not signed in. Reset the user object.
        setUser(null);
      }
      setLoading(false); // Finished checking user auth state
    });

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, []);

  if (loading) return <div>Loading...</div>; // Or a custom loading component

  return (
    <AuthContext.Provider value={{ user, setUser, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
