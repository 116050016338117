import { auth } from '../firebaseConfig';
import ApiService from './apiService';  // Update the path to your actual file location
import { AxiosError } from 'axios';  // Import AxiosError type for error handling

// Separate error handling function for cleaner main functions
const handleErrors = (error: AxiosError): void => {
  console.error('Error encountered:', error);

  if (error.response) {
    console.error('Server response:', error.response.data);
    console.error('Status code:', error.response.status);
    console.error('Headers:', error.response.headers);
  } else if (error.request) {
    console.error('No response received from server. Request:', error.request);
  } else {
    console.error('Error in setting up the request:', error.message);
  }
  throw error;
};

// Fetch user data function
export const fetchUserData = async (): Promise<any> => {  // You may want to replace any with the actual type of the returned data
  console.log('Fetching user data...');
  
  const user = auth.currentUser;

  if (!user) {
    console.log('No user is authenticated.');
    throw new Error('User not authenticated');
  }

  const { email } = user;
  console.log(`Fetching data for email: ${email}`);

  try {
    if (email === null) {
      throw new Error('Email is null');
    }
    return await ApiService.getUser(email);
  } catch (error) {
    handleErrors(error as AxiosError);  // Cast error to AxiosError type
  }



};

