// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, setPersistence, browserLocalPersistence,signOut,sendPasswordResetEmail } from 'firebase/auth';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
// Install Firebase and its types
// Run this command in your terminal: npm install firebase @types/firebase
// Make sure to remove this comment after running the command
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC3i8duqK_vaiN5j_sJretIzJM16KCrdpY",
  authDomain: "jobs-in-construction-806cc.firebaseapp.com",
  projectId: "jobs-in-construction-806cc",
  storageBucket: "jobs-in-construction-806cc.appspot.com",
  messagingSenderId: "89443173047",
  appId: "1:89443173047:web:b854edb34ef1e648d88332",
  measurementId: "G-6E43FMQVDC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
// Enable authentication persistence
setPersistence(auth, browserLocalPersistence);
export const storage = getStorage(app);

// signup


export function signUpWithEmailPassword(email: string, password: string) {
  return createUserWithEmailAndPassword(auth, email, password);
}
// signin
export function signInWithEmailPassword(email: string, password: string) {
  return signInWithEmailAndPassword(auth, email, password);
}

export function sendPasswordReset(email: string){
  return sendPasswordResetEmail(auth, email);
}

// logout

export function logout(){
   return signOut(auth);
}

export const uploadImageToStorage = (folder: string, file: File) => {
  return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `${folder}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed',
          (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
          },
          (error) => {
              console.error('Upload failed:', error);
              reject(error);
          },
          () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  console.log('File available at', downloadURL);
                  resolve(downloadURL);
              });
          }
      );
  });
}