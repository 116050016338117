import React from 'react';
import { Typography, Paper, Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const PaymentFail = ({ errorMessage }) => {
  const navigate = useNavigate();

  return (
    <Paper
      style={{
        padding: '20px',
        width: '600px',
        margin: '0 auto',
        marginTop: '50px',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        style={{ marginBottom: '20px', color: 'red' }}
      >
        Payment failed.
      </Typography>
      <Typography variant="body1" color="error">
        {errorMessage}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: '20px' }}
        onClick={() => navigate('/dashboard')} // Assuming you want to navigate back to the dashboard
      >
        Go to Dashboard
      </Button>
    </Paper>
  );
};
export default PaymentFail;
