import React, { useState, useEffect } from 'react';
import { fetchUserData } from '../services/userService';
import { logout } from '../firebaseConfig.ts';
import StructureDiv from '../components/__structures/StructureDiv';
import HorizontalNavHome from '../components-custom/HorizontalNavHome';
import {
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ApiService from '../services/apiService';

const useStyles = makeStyles((theme) => ({
  profileContainer: {
    marginTop: '2em', // or use padding: '2em 0' if you want padding at the bottom as well
  },
  
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // or any value to ensure it overlays everything else
  },

}));



export default function Profile() {

  const [isLoading, setIsLoading] = useState(true); // for initial data fetch
  const [isSaving, setIsSaving] = useState(false);

  const [userData, setUserData] = useState({
    name: '',
    email: '',
    company: '',
  });

  const classes = useStyles();

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true);
      try {
        const data = await fetchUserData();
        setUserData(data);
      } catch (error) {
        console.error('Failed fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLogout = async () => {
    try {
      await logout();
      // Optionally redirect user after logging out
      // history.push('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const updatedData = await ApiService.updateUser(userData);
      setUserData(updatedData);
      console.log('Profile updated successfully.');
    } catch (error) {
      console.error('Error updating profile:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <React.Fragment>
      <StructureDiv
        bucket1={[
          <HorizontalNavHome />,
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            spacing={3}
            className={classes.profileContainer}
          >
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h2" gutterBottom>
                    User Profile
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={userData.name}
                        name="name"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={userData.email}
                        name="email"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Company"
                        variant="outlined"
                        fullWidth
                        value={userData.company}
                        name="company"
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    justify="flex-end"
                    style={{ marginTop: '1em' }}
                  >
                    <Grid item>
                      <Button variant="contained" onClick={handleLogout}>
                        Logout
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>,
        ]}
      />
        {(isLoading || isSaving) && (
      <div className={classes.overlay}>
        <CircularProgress />
      </div>
    )}
    </React.Fragment>
  );
}
