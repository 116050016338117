import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  centeredBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageStyle: {
    width: '500px', // or any desired width
    height: '200px', // or any desired height
    marginBottom: theme.spacing(2), // spacing below the image
  },
}));

export default function Component(props) {
  const classes = useStyles();

  return (
    <section>
      <Container maxWidth="md">
        <Box py={8} textAlign="center" className={classes.centeredBox}>
          <img src="jic.png" alt="Descriptive Image Text" className={classes.imageStyle} />
          <Typography variant="h5" color="textSecondary">
            Advertise to over 100,000<br/>
            construction workers nationwide
          </Typography>
        </Box>
      </Container>
    </section>
  );
}
