import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import {
  signInWithEmailPassword,
  sendPasswordReset,
} from '../firebaseConfig';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({}));

export default function Component(props) {
  const history = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();

    console.log('Attempting to log in with email:', email);

    try {
      const response = await signInWithEmailPassword(email, password);

      console.log('Login successful:', response);

      history('/dashboard');
    } catch (err) {
      console.error('Login failed with error:', err.message);
      setError(err.message);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordReset(email);
      alert('Password reset email has been sent to your email address.');
    } catch (err) {
      console.error('Error sending password reset email:', err.message);
      alert(err.message);
    }
  };

  return (
    <section>
      <Container maxWidth="xs">
        <Box pt={8} pb={10}>
          <Box mb={3} textAlign="center">
            <Typography variant="h5" component="h2">
              Welcome Back !
            </Typography>
          </Box>
          <Box>
            <form noValidate onSubmit={handleLogin}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="email"
                    id="email"
                    label="Email address"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    id="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <div></div>
              </Grid>
              <Box my={2}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Sign in
                </Button>
              </Box>
              <Box textAlign="right">
                <Link component={RouterLink} to="/signup" variant="body2">
                  Don't have an account yet? Click here.
                </Link>
              </Box>
            </form>
          </Box>
          <Box textAlign="right" mb={1}>
          <Link
            component="button"
            variant="body2"
            onClick={handlePasswordReset}
            style={{ cursor: 'pointer' }}
          >
            Forgot Password?
          </Link>
        </Box>
        </Box>
     
      </Container>
    </section>
  );
}
