import React from 'react';

import StructureDiv from '../components/__structures/StructureDiv';
import HorizontalNavsCustom1 from '../components-custom/HorizontalNavsCustom1';
import SignInCustom2 from '../components-custom/LoginCustom';
import FootersCustom3 from '../components-custom/FootersCustom3';

const meta = {};

export default function Login() {
  return (
    <React.Fragment>
      <StructureDiv bucket1={[<HorizontalNavsCustom1 />, <SignInCustom2 />]} />

      <StructureDiv bucket1={[<FootersCustom3 />]} />
    </React.Fragment>
  );
}

