import React, { useContext, useState, useEffect} from 'react';
import { JobContext } from '../contexts/JobContext';
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Button,
} from '@material-ui/core';
import ApiService from '../services/apiService';
import StripeService from '../services/stripeService';
import { useNavigate } from 'react-router-dom';

const Checkout = () => {
  const context = useContext(JobContext);

  if (!context) {
    throw new Error('Checkout must be used within a JobProvider');
  }
  const { jobs, updateAllJobs } = context;
  console.log('18.log.jobs=', jobs);

  jobs.forEach((job) => {
    if (typeof job.jobType !== 'boolean') {
      console.log('Unexpected job item:', job);
    }
  });

  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const calculateTotal = () => {
    let totalPrice = 0;
    jobs.forEach((job) => {
      if (job.jobType) {
        // changed this line to check for true (premium) or false (standard)
        totalPrice += 4.99;
      } else {
        totalPrice += 0.99;
      }
    });
    setTotal(parseFloat(totalPrice.toFixed(2))); // parse the string back to a number
  };

  const handlePayNow = async () => {
    console.log('46.log:', jobs);
    try {
      console.log('log.pay.now');
      // Augment jobs with price before sending
      const augmentedJobs = jobs.map((job) => ({
        ...job,
        price: job.jobType ? 4.99 : 0.99,
        tempId: job.id,  // Ensure tempId is included
        image: job.image, 
         // if job.jobType is true, price is 4.99, otherwise, price is 0.99
      }));
      console.log('54.augmented.jobs**=', augmentedJobs);

      // Save augmentedJobs to localStorage
      try {
        localStorage.setItem('augmentedJobs', JSON.stringify(augmentedJobs));
      } catch (error) {
        console.error('LocalStorage error:', error);
      }



      const newJobsData = augmentedJobs;
      updateAllJobs(newJobsData);
      console.log('log.64', newJobsData);

        // testing 
     //   navigate('/payment-success'); 

      console.log('41.Sending augmentedJobs:', augmentedJobs);

      
      const { sessionId } = await ApiService.createCheckoutSession(
        augmentedJobs
      );
      console.log('sessionID.70=', sessionId);


    ///put back after testing
    
      // When the customer clicks on the button, redirect them to Checkout.
      // Using the StripeService to handle the redirection.
      console.log('log.54***Job data before redirection:', augmentedJobs);
      const result = await StripeService.redirectToCheckout(sessionId);
      console.log('71 Stripe redirect result:', result);

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network error, display the localized error message to your customer.
        alert(result.error.message);
      }




    } catch (error) {
      // Handle the error gracefully. You can display it or log it for debugging purposes.
      console.error('Error during payment process:', error);
      alert('There was an error processing your payment. Please try again.');
    }


  };

  useEffect(() => {
    calculateTotal();
  }, [jobs]);

  return (
    <Paper
      style={{
        padding: '20px',
        width: '600px',
        margin: '0 auto',
        marginTop: '50px',
      }}
    >
      <Typography variant="h5" style={{ marginBottom: '20px' }}>
        Checkout
      </Typography>

      <List>
        {jobs.map((job) => {
          const jobType = job.jobType ? 'Premium' : 'Standard';
          return (
            <ListItem key={job.id}>
              <ListItemText
                primary={job.title}
                secondary={`${job.description} - ${jobType}`}
              />
              <ListItemSecondaryAction>
                <Typography variant="body1">
                  {job.jobType ? '£4.99' : '£0.99'}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>

      <Typography
        variant="h6"
        style={{ marginTop: '20px', textAlign: 'right' }}
      >
        Total: £{total}
      </Typography>

      <div style={{ marginTop: '20px', textAlign: 'right' }}>
        <Button variant="contained" color="primary" onClick={handlePayNow}>
          Pay Now
        </Button>
      </div>
    </Paper>
  );
};

export default Checkout;
