import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography } from '@material-ui/core';
import ApiService from '../services/apiService';


export default function ActiveJobs() {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        try {
          const data = await ApiService.getJobs(user.email);
          setJobs(data);
        } catch (error) {
          console.error("Error fetching jobs:", error);
        }
      }
    };

    fetchJobs();
  }, []);

  if (jobs.length === 0) {
    return <Typography align="center" style={{ paddingTop: '20px' }}>No Active Jobs</Typography>;
}

  return (
    <div>
      <h1>Active Listings</h1>
     
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Job Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Listing</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {jobs.map(job => (
              <TableRow key={job.id}> {/* Assuming each job has a unique 'id' field */}
                <TableCell>{job.JobTitle}</TableCell>
                <TableCell>{job.jobDescription}</TableCell>
                <TableCell>{job.location}</TableCell>
                <TableCell>{job.premium ? 'Premium' : 'Standard'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
