import React, { useState, useContext, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { JobContext } from '../contexts/JobContext';
import { useAuth } from '../contexts/AuthContext';
import { fetchUserData } from '../services/userService';
import { useParams } from 'react-router-dom';

import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  CircularProgress,
  makeStyles,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Select,
  MenuItem,
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import TitleIcon from '@material-ui/icons/Title';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DescriptionIcon from '@material-ui/icons/Description';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import ImageIcon from '@material-ui/icons/Image';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import HorizontalNavHome from '../components-custom/HorizontalNavHome';
import 'font-awesome/css/font-awesome.min.css';
import { Job } from './types';

const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  jobCard: {
    marginBottom: theme.spacing(2),
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
    transition: 'all 0.3s',
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: '0 4px 10px rgba(0,0,0,0.3)',
    },
  },
  card: {
    width: '100%', // Force the card to take up 100% width of its container
  },
}));

const PostJob: React.FC = () => {
  const classes = useStyles();
  const { user, isAuthenticated } = useAuth();

  const context = useContext(JobContext);
  if (!context) {
    throw new Error('Component must be used within a JobProvider');
  }
  const { jobs, addJob, removeJob, editJob, updateAllJobs } = context;

  const [editing, setEditing] = useState<number | null>(null);

  const { selection } = useParams();
  const capitalizeFirstLetter = (string: string) => {
    if (!string) return ''; // Add this line to handle undefined or null values
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const navigate = useNavigate();

  const handleCheckout = () => {
    navigate('/checkoutparent', { state: { jobs } });
  };

  const [jobDetails, setJobDetails] = useState<Job>({
    company: '',
    email: '',
    title: '',
    location: '',
    description: '',
    contactNumber: '',
    image: null,
    jobType: true, // Assuming premium by default
  });

  const allFieldsFilled = () => {
    return (
      jobDetails.company &&
      jobDetails.email &&
      jobDetails.title &&
      jobDetails.location &&
      jobDetails.description &&
      jobDetails.contactNumber
    );
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const data = await fetchUserData();
        const isPremium = selection === 'premium';
        console.log('debug.122', isPremium); // Determine job type based on selection
        const tempId = Math.floor(Math.random() * 1000000); // Generates a random number between 0 and 999999

        setJobDetails(
          (prevDetails): Job => ({
            ...prevDetails,
            company: data.company,
            email: data.email,
            jobType: isPremium,
            id: tempId, // Update jobType
          })
        );
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, [selection]); // Add selection as a dependency

  const handleSubmit = () => {
    if (editing) {
      editJob(editing, jobDetails);
      setEditing(null); // reset editing mode
    } else {
      console.log('136. post.jobDetails=', jobDetails);
      addJob(jobDetails);
    }

    // This line is the new code added to reset the form after submission
    setJobDetails({
      company: '',
      email: '',
      title: '',
      location: '',
      description: '',
      contactNumber: '',
      image: null,
      jobType: true, // Reset to 'premium' by default
    });
  };

  const handleDelete = (jobId: number) => {
    removeJob(jobId);
  };

  const handleEdit = (jobId: number) => {
    const jobToEdit = jobs.find((job) => job.id === jobId);
    if (jobToEdit) {
      setJobDetails(jobToEdit);
      setEditing(jobId); // set to edit mode for this job
    } else {
      console.error(`No job found with id ${jobId}`);
      // Handle this case as needed
    }
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setJobDetails((prev) => ({ ...prev, image: file }));
    }
  };

  return (
    <div>
      <HorizontalNavHome />

      <Grid
        container
        spacing={4}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{
          minHeight: 'calc(100vh - 64px)',
          padding: '2rem',
          position: 'relative',
        }}
      >
        {loading && (
          <div className={classes.overlay}>
            <CircularProgress />
          </div>
        )}

        <Grid item xs={12} md={8}>
          <Card elevation={5} style={{ padding: '1rem' }}>
            <CardHeader
              title={`${capitalizeFirstLetter(
                jobDetails.jobType ? 'Premium' : 'Standard'
              )} Listing`}
              action={
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCheckout}
                  disabled={jobs.length === 0}
                >
                  Checkout
                </Button>
              }
            />

            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    id="company"
                    name="company"
                    label="Company"
                    value={jobDetails.company}
                    onChange={(e) =>
                      setJobDetails({ ...jobDetails, company: e.target.value })
                    }
                    InputProps={{
                      startAdornment: <BusinessIcon />,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    id="email"
                    name="email"
                    label="Email"
                    type="email"
                    value={jobDetails.email}
                    onChange={(e) =>
                      setJobDetails({ ...jobDetails, email: e.target.value })
                    }
                    InputProps={{
                      startAdornment: <EmailIcon />,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    id="title"
                    name="title"
                    label="Job Title"
                    value={jobDetails.title}
                    onChange={(e) =>
                      setJobDetails({ ...jobDetails, title: e.target.value })
                    }
                    InputProps={{
                      startAdornment: <TitleIcon />,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    id="location"
                    name="location"
                    label="Location"
                    value={jobDetails.location}
                    onChange={(e) =>
                      setJobDetails({ ...jobDetails, location: e.target.value })
                    }
                    InputProps={{
                      startAdornment: <LocationOnIcon />,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    multiline
                    rows={4}
                    id="description"
                    name="description"
                    label="Job Description"
                    value={jobDetails.description}
                    onChange={(e) =>
                      setJobDetails({
                        ...jobDetails,
                        description: e.target.value,
                      })
                    }
                    InputProps={{
                      startAdornment: <DescriptionIcon />,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    id="contactNumber"
                    name="contactNumber"
                    label="Contact Number"
                    value={jobDetails.contactNumber}
                    onChange={(e) =>
                      setJobDetails({
                        ...jobDetails,
                        contactNumber: e.target.value,
                      })
                    }
                    InputProps={{
                      startAdornment: <PhoneIcon />,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div>
                    <input
                      accept="image/*"
                      id="image"
                      name="image"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleImageChange}
                    />
                    <label htmlFor="image">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        startIcon={<ImageIcon />}
                      >
                        Upload Image
                      </Button>
                    </label>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <Select
                    value={jobDetails.jobType ? 'premium' : 'standard'} // convert boolean to string for Select value
                    onChange={(e) => {
                      const isPremium = e.target.value === 'premium'; // convert string back to boolean for state
                      setJobDetails({ ...jobDetails, jobType: isPremium });
                    }}
                  >
                    <MenuItem value="standard">Standard</MenuItem>
                    <MenuItem value="premium">Premium</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={handleSubmit}
                    disabled={!allFieldsFilled()}
                  >
                    {editing ? 'Update Job' : 'Post Job'}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card
            elevation={5}
            style={{ padding: '1rem' }}
            className={classes.card}
          >
            <CardHeader title="Saved Jobs" />
            <CardContent>
              <List>
                {jobs.map((job) => (
                  <ListItem key={job.id} className={classes.jobCard}>
                    <ListItemText
                      primary={job.title}
                      secondary={`${job.company}${
                        job.jobType ? ` (Premium)` : ` (Standard)`
                      }`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                          if (typeof job.id === 'number') {
                            handleDelete(job.id);
                          } else {
                            console.error('job.id is undefined');
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>

                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                          if (job.id !== undefined) {
                            handleDelete(job.id);
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

PostJob.propTypes = {};

export default PostJob;
