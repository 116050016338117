import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import HomeIcon from '@material-ui/icons/Home'; // Import the Home icon
import { Link as RouterLink } from 'react-router-dom'; // To redirect to Dashboard

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  linkBrand: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  },
  linkBrandSmall: {
    display: 'none',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'inline-block',
    }
  },
  secondaryButton: {
    marginRight: theme.spacing(2),
  },
  drawerContainer: {
    width: 256,
  }
}));

interface ComponentProps {
  // define your props here if any
}

const Component: FC<ComponentProps> = (props) => {
  const classes = useStyles();

  const [state, setState] = useState({ open: false });

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift') {
      return;
    }

    setState({ ...state, open });
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Link href="#" variant="h5" color="inherit" underline="none" className={classes.linkBrand}>
          <img src="https://static.shuffle.dev/uploads/files/5a/5a14da5767fef7d1842df02336b8c145a44c6506/white-logo4.png" alt="" width="120" />
        </Link>
        {/* Updated Button to Redirect to Dashboard with Home Icon */}
        <Button 
          color="inherit" 
          className={classes.secondaryButton} 
          component={RouterLink} 
          to="/dashboard"
          startIcon={<HomeIcon />}  // Add Home Icon
        >
        </Button>
      </Toolbar>
      <Drawer anchor="left" open={state.open} onClose={toggleDrawer(false)}>
        <div className={classes.drawerContainer}>
          
        </div>
      </Drawer>
    </AppBar>
  );
};

export default Component;
