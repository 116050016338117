import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { emphasize, fade, darken, lighten } from '@material-ui/core/styles/colorManipulator';

import clsx from 'clsx';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    }
  },
  iconsBoxRoot: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    }
  },
  copy: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      order: 12,
    }
  }
}
));

export default function Component(props) {
  const classes = useStyles();

  return (
<footer className={classes.root}>
  <Container maxWidth="lg">
    <Box py={6} display="flex" flexWrap="wrap" alignItems="center">
      <Typography color="textSecondary" component="p" gutterBottom={false} className={classes.copy}>© 2023 Jobs In Construction. All rights reserved.</Typography>
      <Box ml="auto" className={classes.iconsBoxRoot}>
     
      </Box>
    </Box>
  </Container>
</footer>
  );
}