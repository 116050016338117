import React from 'react';

import StructureDiv from '../components/__structures/StructureDiv';
import HorizontalNavsCustom1 from '../components-custom/HorizontalNavsCustom1';
import FootersCustom3 from '../components-custom/FootersCustom3';

const meta = {};

const PrivacyPolicy = () => {
    return (
        <React.Fragment>
            <StructureDiv bucket1={[<HorizontalNavsCustom1 />]} />

            <div className="privacy-policy-container" style={{ paddingLeft: '20px' }}>
                <h1>Privacy Policy for Jobs in Construction</h1>
                <p><em>Last Updated: September 28, 2023</em></p>

                <h2>1. Introduction</h2>
                <p>
                    Thank you for using 'Jobs in Construction'. We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we handle your personal data, your privacy rights, and how the law protects you.
                </p>

                <h2>2. Data Controller</h2>
                <p>
                    'Jobs in Construction' is the data controller and is responsible for your personal data.
                </p>
                <strong>Contact Details</strong>:
                <ul>
                    <li>Email: [Your Email]</li>
                    <li>Address: [Your Address]</li>
                </ul>

                <h2>3. Personal Data We Collect</h2>
                <ul>
                    <li><strong>Usage Data</strong>: Information about how you use our app and services.</li>
                    <li><strong>Location Data</strong>: We use location data to showcase relevant jobs near you, but only when you grant us permission.</li>
                    <li><strong>Technical Data</strong>: Details about the device you use to access our app, including IP address, browser type/version, and other technologies on the devices you use.</li>
                    <li><strong>Contact Data</strong>: Email address or phone number if provided for notifications or account services.</li>
                </ul>

                <h2>4. How We Use Your Data</h2>
                <p>
                    We will only use your personal data when the law allows us to. Most commonly, we will use your personal data:
                </p>
                <ul>
                    <li>To provide and maintain our service.</li>
                    <li>To notify you about changes to our service or any products or services you have requested.</li>
                    <li>For internal analysis and understanding of the effectiveness of our service.</li>
                    <li>For compliance with legal obligations.</li>
                </ul>

                {/* Continue with the rest of the sections in a similar format... */}

                <h2>11. Contact</h2>
                <p>
                    For any queries related to this privacy policy or our data handling practices, please contact us at [Your Contact Information Here].
                </p>
            </div>

            <StructureDiv bucket1={[<FootersCustom3 />]} />
        </React.Fragment>
    );
}

export default PrivacyPolicy;
