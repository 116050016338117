import React from 'react';

import StructureDiv from '../components/__structures/StructureDiv';
import HorizontalNavLogout from '../components-custom/HorizontalNavLogout';
import ElementH5 from '../components/__elements/ElementH5';
import StructureContainer from '../components/__structures/StructureContainer';
import PricingCustom2 from '../components-custom/PricingCustom2';
import ActiveJobs from '../components-custom/ActiveJobs';
import { useAuth } from '../contexts/AuthContext';

const meta = {};

export default function Dashboard() {
  // Use the useAuth hook to get authentication state and methods
  const { user, isAuthenticated } = useAuth();

  // You can now use the `user` and `isAuthenticated` variables in your component.
  // For example, you might want to show/hide certain parts based on the user's authentication status,
  // or display the user's name, email, etc.

  return (
    <React.Fragment>
      <StructureDiv
        bucket1={[
          <HorizontalNavLogout />,
          // Perhaps you want to greet the user if they are authenticated
          isAuthenticated && <ElementH5>{`Welcome, ${user.email}`}</ElementH5>,
          <StructureDiv
            bucket1={[
              <StructureDiv bucket1={null} />,
              <div style={{ padding: '0 20px' }}>
                <ActiveJobs />
              </div>,
              <StructureDiv bucket1={null} />,
            ]}
          />,
          <StructureContainer bucket1={null} />,
        ]}
      />
      <StructureContainer bucket1={[<PricingCustom2 />]} />
    </React.Fragment>
  );
}

