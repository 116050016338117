import { loadStripe } from '@stripe/stripe-js';

// Load Stripe.js with your publishable key.
const stripePromise = loadStripe('pk_live_51NAEUYEgbhVRsJkbWfK65hvIVbbnUtcfd1umgClxzegi0zReZYUlGXlh9NGPs6rrGt6Lj2oE3YgppMcnqH3VY3QJ00gBHmUgAT');

const redirectToCheckout = async (sessionId: string) => {
    const stripe = await stripePromise;
    if (!stripe) {
        throw new Error('Stripe failed to initialize');
    }
    return stripe.redirectToCheckout({ sessionId });
};


// More Stripe related functions can be added here...

export default {
    redirectToCheckout
    // ... other exported functions
};
