import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    "palette": {
        "primary": {
            "main": "#F60000",
            "light": "#F60000",
            "dark": "rgba(246, 0, 0, 0.96)"
        },
        "secondary": {
            "main": "#00344E",
            "light": "#00344E",
            "dark": "#00344E"
        }
    },
    "typography": {
        "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif"
    }
});

export default theme;

